import React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import { Container } from "../components/Layout/Container";
import { SectionTitle } from "../components/Layout/SectionTitle";
import { Section } from "../components/Layout/Section";
import TopBanner from "../components/TopBanner";
import Video from "../components/Video";
import * as aboutPageStyles from "../styles/about-page.module.scss"
import SimpleHomePageCarousel from "../components/SlickCarousel";

const Index = ({pageContext, data, path}) => {
  // console.log("ABOUT REST: ", path)
  const bgImage = data.template.sections[0].items[0].imageFile.publicURL
  const content = data.template.sections[1].items[0].locales.find(l => l.iso === pageContext.lang).value
  const videoID = data.template.sections[2].items[0].locales.find(l => l.iso === pageContext.lang).value
  const selectedPress = data.template.sections[3].selectedModules
  const allPress =selectedPress.map(n => {
    return {
      uid: n.uid,
      image:  n.imageFile?.childImageSharp,
      imageURL: n.imageFile?.publicURL,
      title:  n.locales.find(l => l.iso === pageContext.lang).title,
      value:  n.locales.find(l => l.iso === pageContext.lang).value,
      pdf: n.hardCopyFile?.publicURL
    }
  })
  return (
    <Layout pathName={path} insidePage={true} breadCrumbLinks={pageContext.breadCrumb}>
        <SectionTitle>
          Joseph Sacco
        </SectionTitle>
      <TopBanner style={{padding: "0 16px"}} withOutOverlay insidePage image={bgImage} title={pageContext.name} />
      <Container>
          <div className={aboutPageStyles.content} dangerouslySetInnerHTML={{__html: content }} />
      </Container>
      <Container>
        <Section style={{padding:"16px"}}>
          <SectionTitle>
            Our Process
          </SectionTitle>
          <Video srcId={videoID} poster="../images/videoPoster.jpg" options={{
            controls: ['progress', 'play', 'current-time']
          }}/>
        </Section>
      </Container>
      <Container>
        <SectionTitle style={{marginTop:"116px"}}>
          Featured in
        </SectionTitle>
        <SimpleHomePageCarousel linkText="Read" press options={{
          slidesToShow: 5,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        }} nodes={allPress} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
    query($templateName: String) {
      template(name: {eq: $templateName}) {
        locales {
          iso
          metaDescription
          metaKeywords
          metaTitle
          value
        }
        sections {
        ... on SectionModuleGet {
            __typename
            selectedModules {
            ... on PressGet {
                uid
                type
                hardCopyFile {
                  publicURL
                }
                imageFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
                locales {
                  iso
                  value
                  content
                  description
                  title
                }
              }
            }
          }
          items {
            key
          ... on DataItemAssetGet {
              __typename
              imageFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                }
                publicURL
              }
            }
          ... on DataItemTextGet {
              __typename
              locales {
                iso
                value
              }
            }
          }
        }
  }
    }
`
export const Head = ({data, pageContext}) => {
  const { metaTitle, metaDescription} = data.template.locales.find(l => l.iso === pageContext.lang)
  return  <Seo title={metaTitle} description={metaDescription} />
}
export default Index
